import api from '@/lib/axiosApi'

export const dictMKB10 = {
  getDictMKB10: currentVersion => {
    return api.get(`dict/mkb10?version=${currentVersion || ''}`)
  },

  importDictMKB10: () => {
    return api.post(`dict/mkb10/nsi/import`)
  },

  checkDictMKB10NSIUpdate: () => {
    return api.get(`dict/mkb10/nsi/check-updates`)
  },
}

export default dictMKB10
