import api from '../../api'
import ls from '@/lib/localStorageUtils'

const dictName = 'dictMKB'

const head = 'Справочник МКБ-10'

const state = () => ({
  // Info
  info: ls.loadDictVersion(dictName, {}),
  // ДАнные справочника
  data: null,
  // Версия
  nsiData: null,
})

const getters = {
  version: (_, getters) => getters.info.version,
  description: (_, getters) => getters.info.description,
  info: state => state.info || {},
  data: state => state.data || [],

  // Может ли пользователь обновлять справочник
  canUpdate: (state, getters, rootState, rootGetters) =>
    rootGetters.isAdmin ||
    rootGetters.userAttributes?.dict?.canUpdateMKB10 ||
    false,
  // текущая версия НСИ справочника МКБ-10
  nsiVersion: state => state.nsiData?.current_version ?? '',
  nsiUpdateError: state => !!state.nsiData?.error,
}

const mutations = {
  /**
   * ПОЛУЧАЕМ (И ПЕРЕУСТАНАВЛИВАЕМ) ЗНАЧЕНИЯ ВЕРСИИ И СПРАВОЧНИКА
   */
  GET_LOCAL(state) {
    if (!state.data)
      try {
        state.info = ls.loadDictVersion(dictName, {})
        state.data = ls.loadDictData(dictName)
      } catch (error) {
        state.info = {}
        state.data = null
      }
  },
  /**
   * УСТАНАВЛИВАЕМ ЗНАЧЕНИЕ ВЕРСИИ И СПРАВОЧНИКА
   */
  SET_DICT(state, { version, description, data }) {
    state.info = { version, description }
    state.data = data
    ls.saveDict(dictName, { version, description }, data)
  },
  /**
   * УСТАНАВЛИВАЕМ Данные проверки НСИ
   */
  SET_NSI_DATA(state, nsiData) {
    state.nsiData = nsiData
  },
}

const actions = {
  /**
   * ПОЛУЧАЕМ СТПРАВОЧНИК И СВРАВНИВАЕМ ВЕРСИИ...
   */
  GET_MKB_DICT: async ({ commit, getters }) => {
    const currentVersion = getters.version || ''
    try {
      const { version, description, data } = await api.getDictMKB10(
        currentVersion
      )
      if (data) {
        // всем в корне сделаем признак root = true
        data.forEach(elem => {
          elem.root = true
        })
        commit('SET_DICT', { version, description, data })
      } else {
        commit('GET_LOCAL')
      }
    } catch (error) {
      commit(
        'SET_ERROR',
        {
          head,
          text: 'Ошибка загрузки справочника МКБ-10',
          error,
        },
        { root: true }
      )
      throw error
    }
  },
  IMPORT_MKB_DICT: async ({ commit, dispatch }) => {
    try {
      const { messages } = await api.importDictMKB10()
      if (!messages?.[0]) {
        commit('SET_DICT', {})
        await dispatch('GET_MKB_DICT')
      }
      return messages?.[0] || 'Справочник МКБ-10 обновлён успешно!'
    } catch (error) {
      commit(
        'SET_ERROR',
        {
          head,
          text: 'Ошибка обновления справочника МКБ-10',
          error,
        },
        { root: true }
      )
      throw error
    }
  },
  CHECK_MKB_DICT_NSI_UPDATE: async ({ commit }) => {
    try {
      const data = await api.checkDictMKB10NSIUpdate()
      commit('SET_NSI_DATA', data)
      if (data.error) {
        console.warn(data.error)
      }
      return data
    } catch (error) {
      commit(
        'SET_ERROR',
        {
          head,
          text: 'Ошибка проверки обновления справочника МКБ-10',
          error,
        },
        { root: true }
      )
      throw error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
