import { createNamespacedHelpers } from 'vuex'
import { registerModule, unregisterModule } from '@/store'
import { dictMKB10Module } from './const'

import dictMKB10 from './dictMKB10'

export default {
  name: dictMKB10Module,
  register: () => registerModule(dictMKB10Module, dictMKB10),
  unregister: () => unregisterModule(dictMKB10Module),
  // map store
  ...createNamespacedHelpers(dictMKB10Module),
}
